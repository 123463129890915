import React, { ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

interface ILayoutProps {
  title?: string;
  children: ReactElement;
}

const Layout: React.FunctionComponent<ILayoutProps> = ({ title, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { siteMetadata } = data.site;
  const year = new Date().getFullYear();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || siteMetadata.title}</title>
        <link rel="canonical" href="http://www.hapusuveranauthority.org" />
      </Helmet>
      <div className="flex flex-col min-h-screen">
        <header className="text-center py-8">
          <a href="/">
            <strong className="text-2xl uppercase">{siteMetadata.title}</strong>
          </a>
        </header>
        <main className="flex-grow flex flex-col">{children}</main>
        <footer className="text-center py-8 uppercase">
          &copy; {year} {siteMetadata.title}
        </footer>
      </div>
    </>
  );
};

export default Layout;
